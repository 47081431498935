import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiFillInstagram,
} from "react-icons/ai";
import {
  FaLinkedinIn,
  FaWordpress,
} from "react-icons/fa";
import axios from "axios";
import queryString from "query-string";
import { Button } from "@material-ui/core";

class Home2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  myRequest = (url) => {
    let response;
    try {
      response = axios.post(url,null,null)
    } catch (e) {
      response = e;
    }
    console.log(response)
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleSubmit = (e) => {
    const data = {
      ...this.state
    }
    e.preventDefault()
    const formUrl = "https://docs.google.com/forms/d/1tMYlZEc-TZD7bFyrK9hKuIqi8J1bDWnwA-90-blpAX4/formResponse";
    const q = queryString.stringifyUrl({
      url: formUrl,
      query: data
    })
    this.myRequest(q)
    e.target.reset()
  }

  render() {
    return (
      <Container fluid className="home-about-section" id="about">
        <Container>
          <Row>
            <Col md={12} className="home-about-description">
              <h1 style={{ fontSize: "2.6em" }}>
                ABOUT THIS <span className="purple"> WEBSITE </span>
              </h1>
            </Col>
            <Col md={8} className="home-about-description">
              <p className="home-about-body">
                Hello! <b className="purple">Welcome</b> to my personal website!
                <br />
                In this website, I show some of <b className="purple">my projects and experiences</b>.
                <br />
                I actually made this website <b className="purple">for fun</b>, to fill my free time.
                <br />
                If you have any <b className="purple">question, comment, or anything</b> about me or
                <br />
                my website, feel free to <b className="purple">fill the form</b> below!
                <br />
                <br />
              <form onSubmit = {this.handleSubmit}>
                <input
                  class="input"
                  type="email"
                  name="entry.617196760"
                  placeholder="Email (Optional)"
                  onChange = {this.handleChange}
                  />
                <br />
                <input
                  class="input"
                  type="text"
                  name="entry.1899088076"
                  placeholder="Question/Comment"
                  onChange = {this.handleChange}
                  />
                <br />
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </form>
              </p>
            </Col>
            <Col md={4} className="myAvatar">
              <Tilt>
                <img src={myImg} className="img-fluid" alt="avatar" />
              </Tilt>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <Row>
            <Col md={12} className="home-about-social">
              <h1>FIND ME ON</h1>
              <ul className="home-about-social-links">
                <li className="social-icons">
                  <a
                    href="https://github.com/hrjm"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour  home-social-icons"
                  >
                    <AiFillGithub />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href="https://www.linkedin.com/in/handrata/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour  home-social-icons"
                  >
                    <FaLinkedinIn />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href="https://www.instagram.com/handrataroy/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                  >
                    <AiFillInstagram />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href="https://handratamarbun.wordpress.com"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour  home-social-icons"
                  >
                    <FaWordpress />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}
export default Home2;
