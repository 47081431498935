import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import BlogCard from "./BlogsCards";
import Particle from "../Particle";

import ajarind from "../../Assets/Projects/ajarind.png";
import aksitpb from "../../Assets/Projects/aksi-tpb.jpeg";
import impact from "../../Assets/Projects/impact.jpeg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My <strong className="purple">Experiences </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ajarind}
              isBlog={false}
              title="AJARIND"
              description="Website based on Wordpress, Codeigniter, and SQL."
              link="https://ajarind.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={impact}
              isBlog={false}
              title="IMPACT 2.0"
              description="Website based on ReactJS and MongoDB."
              link="https://impactitb.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={aksitpb}
              isBlog={false}
              title="AKSI TPB 2020 ITB"
              description="Website based on Wordpress, Laravel, and SQL."
              link="https://aksitpb20.com"
            />
          </Col>
        </Row>
        <h1 className="project-heading">
          My Recent <strong className="purple">Blog </strong> Posts
        </h1>
        <p style={{ color: "white" }}>Read some of my blogs here</p>
        <Row style={{ justifyContent: "center" }}>
          <Col md={4} className="blog-card">
            <BlogCard
              imgPath="https://handratamarbun.files.wordpress.com/2021/06/image.png"
              link="https://handratamarbun.wordpress.com/2021/06/30/identitas-mahasiswa/"
              title="Identitas Mahasiswa"
              // site="handratamarbun.wordpress.com"
            />
          </Col>
          <Col md={4} className="blog-card">
            <BlogCard
              imgPath="https://handratamarbun.files.wordpress.com/2020/10/data-privacy.jpg"
              link="https://handratamarbun.wordpress.com/2020/10/08/privasi-data/"
              title="Privasi Data"
              // site="handratamarbun.wordpress.com"
            />
          </Col>
          <Col md={4} className="blog-card">
            <BlogCard
              imgPath="https://handratamarbun.files.wordpress.com/2020/09/vuca.jpg"
              link="https://handratamarbun.wordpress.com/2020/09/21/water-crisis-in-indonesia/"
              title="Water Crisis In Indonesia"
              // site="handratamarbun.wordpress.com"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
