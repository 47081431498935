import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import Resumecontent from "./ResumeContent";
// import pdf from "../../Assets/Handrata Roy Josia.pdf";
// import { AiOutlineDownload } from "react-icons/ai";

function Resume() {
  return (
    <Container fluid className="resume-section">
      <Particle />
      <Container>
        {/* <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row> */}
        <h1 className="project-heading" style={{ paddingBottom: "10px" }}>
          My <strong className="purple">Resume </strong>
        </h1>
        <Row className="resume">
          <Col md={6} className="resume-left">
            <h3 className="resume-title">Experience</h3>
            <Resumecontent
              title="Founder of AJARIND"
              date="July 2020 - Present"
              content={[
                "Being a founder, website developer, and online tutor of an online-based learning startup.",
              ]}
            />
            <Resumecontent
              title="Head of Web Development at IMPACT 2.0"
              date="February 2021 - July 2021"
              content={[
                "Lead a team of students to make a website for IMPACT 2.0 competition.",
              ]}
            />
            <Resumecontent
              title="Head of IT at Aksi TPB 2020"
              date="September 2020 - October 2020"
              content={[
                "Leading a team of students to make a website for Aksi TPB ITB 2020 and ensuring webinars run well.",
              ]}
            />
            {/* <h3 className="resume-title">Language</h3>
            <Resumecontent
              title="Bahasa Indonesia"
              content={[
                "Native or bilingual proficiency",
              ]}
            />
            <Resumecontent
              title="English"
              content={[
                "Professional working proficiency",
              ]}
            />
            <Resumecontent
              title="Bahasa Melayu"
              content={[
                "Professional working proficiency",
              ]}
            /> */}
          </Col>
          <Col md={6} className="resume-right">
            <h3 className="resume-title">Education</h3>
            <Resumecontent
              title="INSTITUT TEKNOLOGI BANDUNG"
              date="2020 - Present"
              content={["Electrical Power Engineering"]}
            />
            <Resumecontent
              title="SMAN 1 TANGERANG"
              date="2017 - 2020"
              content={["High School Diploma"]}
            />
            <h3 className="resume-title">Achievements</h3>
            <Resumecontent
              title="Second Place at OSK 2019"
              date="February 2019"
              content={["Majoring in Mathematics"]}
            />
          </Col>
        </Row>
        {/* <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
          <AiOutlineDownload />&nbsp;Download CV
          </Button>
        </Row> */}
      </Container>
    </Container>
  );
}

export default Resume;
