import React from 'react';
import styled from "styled-components";

const SnakesWindow = styled.div`
    text-align: center;
    font-size: clamp(20px, 2.5vw, 24px);
    margin-top: 7.5em;
    margin-bottom: 6em;
`;

export default function GameOver(props) {
  return (
    <SnakesWindow>
      <div
        id="GameBoard"
        style={{
          width: props.width,
          height: props.height,
          borderWidth: props.width / 50,
        }}>
        <div id="GameOver" style={{ fontSize: props.width / 15 }}>
          <div id="GameOverText">GAME OVER</div>
          <div id="FinalScore">Your score: {props.score}</div>
          <div id="FinalScore">
            {props.newHighScore ? "New local " : "Local "}high score:{" "}
            {props.highScore}
          </div>
          <div id="PressSpaceText">Press Space to restart</div>
        </div>
      </div>
    </SnakesWindow>
  )
}