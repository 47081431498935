import React from "react";
import { useHistory } from 'react-router';
import { Container } from "react-bootstrap";
import Particle from "../Particle";
import { FaSadCry } from "react-icons/fa"

function Home() {
  const history = useHistory()

  const [ seconds, setSeconds ] = React.useState(10);

  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setTimeout(() => setSeconds(0), 1000);
    }
  });

  setTimeout(() => {
    history.push("/")
  }, 11000)

  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="not-found">
          <div>
            <h1 className="not-found-emoji"><FaSadCry /></h1>
            <br />
            <h1>404 PAGE NOT FOUND</h1>
            <h3>I am sorry but the page you are looking for does not exist.</h3>
            <h5>You will be redirected back to the main page in {seconds} seconds ...</h5>
          </div>
        </Container>
      </Container>
    </section>
  );
}

export default Home;
