import React from "react";
import Particle from "../Particle";

import BlogPreview from "./BlogPreview";

export default function Blog () {
    return (
      <div>
        <Particle />
        <BlogPreview />
      </div>
    );
}
