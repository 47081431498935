import React, { Component } from "react";
import { Container } from "react-bootstrap";
import axios from "axios";

import ArticlePreview from "./ArticlePreview";

export default class BlogPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    };
  }
  componentDidMount() {
    axios
      .get(
        "https://public-api.wordpress.com/rest/v1/sites/handratamarbun.wordpress.com/posts"
      )
      .then(res => {
        console.log(res.data.posts);
        this.setState({ posts: res.data.posts });
      })
      .catch(error => console.log(error));
  }

  render() {
    return (
      <Container fluid className="about-section">
        <Container>
          <div>
            <h1>Blogs</h1>
            <a href = "https://handratamarbun.wordpress.com">handratamarbun.wordpress.com</a>
            <br />
            <br />
            {this.state.posts.map(post => <ArticlePreview post={post} />)}
          </div>
        </Container>
      </Container>
    );
  }
}
