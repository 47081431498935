import React from "react";
import styled, { css } from "styled-components/macro";
import Button from "react-bootstrap/Button";
import { FaSmileBeam } from "react-icons/fa";

const Intro = styled.div`
    text-align: center;
    font-size: clamp(20px, 2.5vw, 24px);
    margin-top: 8em;
    margin-bottom: 6.5em;
    color: white;
`;

const btnCSS = css`
    margin-top: 2em;
    margin-bottom: 1em;
`;


const Games = () => {
 
    return (
        <Intro>
            <h1 className="not-found-emoji"><FaSmileBeam /></h1>
            <br />
            <h1>CHOOSE YOUR GAME</h1>
            <Button href="#/games/quiz" size="lg" css={btnCSS}>QUIZ</Button>
            <br />
            <Button href="#/games/snakes" size="lg">SNAKES</Button>
        </Intro>
    )
}

export default Games;