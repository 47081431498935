import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function ProfileCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Handrata Roy Josia Marbun </span>
            from <span className="purple"> Jakarta, Indonesia.</span> I am an Undergradute Student at <span className="purple">Bandung Institute of Technology.</span>
            <br />
            <br /><ImPointRight />{" "}A motivated person who uses all the potential and experience he has so far to become a useful human being for himself and others. 
            <br />
          </p>
          <p style={{ marginBlockEnd: 0, color: "rgb(155 126 172)" }}>
            "Don't worry, be HAPPY!
          </p>
          <footer className="blockquote-footer">Handrata</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default ProfileCard;
