import React, { Component } from "react";
import { Button } from "@material-ui/core";

export default class ArticlePreview extends Component {
  removeUnicode(string) {
    if (string.indexOf("&#8217;") >= 0) {
      return this.removeUnicode(string.replace("&#8217;", "'"));
    } else if (string.indexOf("&#8220;") >= 0) {
      return this.removeUnicode(string.replace("&#8220;", "'"));
    } else if (string.indexOf("&#8221;") >= 0) {
      return this.removeUnicode(string.replace("&#8221;", "'"));
    } else {
      return string.replace("<p>", "").replace("[&hellip;]</p>", "...");
    }
  }

  render() {
    const excerpt = this.removeUnicode(this.props.post.excerpt);

    if (this.props.post) {
      return (
        <div className="article">
          <h1 className="text-center">{this.props.post.title}</h1>
          <a href={"#/blog/" + this.props.post.ID} className="blackLink">
            {this.props.post.featured_image ? (
              <img
                className="img-responsive webpic"
                alt="article header"
                src={this.props.post.featured_image}
              />
            ) : (
              ""
            )}
            <div className="content">{excerpt}</div>
          </a>
          <a href={"#/blog/" + this.props.post.ID}>
            <Button variant="contained" className="read-more">
              Read More
            </Button>
          </a>
        </div>
      );
    } else {
      return null;
    }
  }
}
