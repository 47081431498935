import React, { useState } from 'react';
import Start from './Start';
import Snakes from './Snakes';

export default function SnakesMain() {

  const [start, setStart] = useState(false);

  return (
    <div>
      { start ? <Snakes /> : <Start props={setStart} />} 
    </div>
  );
}