import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiWindows,
  DiCodeigniter,
  DiReact,
  DiPython,
  DiGit,
} from "react-icons/di";
import {
  FcElectricalSensor,
  FcElectricalThreshold,
  FcElectricity,
  FcElectroDevices,
  FcElectronics,
} from "react-icons/fc";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <FcElectricalSensor />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FcElectricalThreshold />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FcElectricity />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FcElectroDevices />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FcElectronics />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiWindows />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiCodeigniter />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
    </Row>
  );
}

export default Techstack;
