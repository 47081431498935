import React, { useState } from 'react';
import Start from './Start';
import Questions from './Questions';

export default function QuizMain() {

  const [start, setStart] = useState(false);

  return (
    <div>
      { start ? <Questions /> : <Start props={setStart} />} 
    </div>
  );
}