import React from "react";
import styled, { css } from "styled-components/macro";
import Button from "../Button";
import { FaSmileBeam } from "react-icons/fa";

const Intro = styled.div`
    text-align: center;
    font-size: clamp(20px, 2.5vw, 24px);
    margin-top: 8.5em;
    margin-bottom: 6em;
    color: white;
`;

const btnCSS = css`
    margin-top: 2.5em;
`;


const Start = ({props}) => {

    const startQuiz = () => props(true)
 
    return (
        <Intro>
            <h1 className="not-found-emoji"><FaSmileBeam /></h1>
            <br />
            <h2>WELCOME TO THE SNAKES GAME</h2>
            <h3>click the button below to start</h3>
            <Button onClick={startQuiz} css={btnCSS}>START</Button>
        </Intro>
    )
}

export default Start;