import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillGithub,
  AiFillInstagram,
} from "react-icons/ai";
import {
  FaLinkedinIn,
  FaWordpress,
} from "react-icons/fa";
import ScrollToTop from "../components/ScrollToTop";

function Footer() {
  let date = new Date();
  let datenumber = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <ScrollToTop />
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://github.com/hrjm/"
                style={{ color: "white" }}
              >
                <AiFillGithub />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/in/handrata/"
                style={{ color: "white" }}
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.instagram.com/handratamarbun/"
                style={{ color: "white" }}
              >
                <AiFillInstagram />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://handratamarbun.wordpress.com/"
                style={{ color: "white" }}
              >
                <FaWordpress />
              </a>
            </li>
          </ul>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3>{datenumber}/{month}/{year}</h3>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
