import styled from "styled-components";
import { Button } from "../Button";

const GameOverWindow = styled.div`
    text-align: center;
    font-size: clamp(20px, 2.5vw, 24px);
    margin-top: 10em;
    margin-bottom: 6em;
`;

const Title = styled.h1`
    margin-top: 4em;
    font-size: 48px;
    color: white;
`;

const Points = styled.p`
    font-size: 24px;
    margin-bottom: 1em;
    color: white;
`;

const Score = styled.p`
    font-size: 24px;
    margin-bottom: 2em;
    color: white;
`;

const GameOver = ({pts}) => {

    const refreshPage = () => window.location.reload();

    return (
        <GameOverWindow>
            <Title>Game Over</Title>
            <Points>You did {pts} out of 10!</Points>
            <Score>Final score = {pts}0</Score>
            <Button onClick={refreshPage}>Retry</Button>
        </GameOverWindow>
    )
}

export default GameOver;